/* Create Brand Picker Modal */
/* Should be able to select a brand from a list of brands */
/* Should be able to search for a brand */
/* Should be able to create a new brand */

import { connect } from "react-redux";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Form from "../../../components/Form";
import SubmissionPage from "./SubmissionPage";
import LoadingPage from "./LoadingPage";
import APIConnection from '../../../api';
import store from '../../../redux/store';
import { setNotification } from '../../../redux/actions/notification';

const CreateAppPage = (props) => {
    const navigate = useNavigate();
    const [app, setApp] = useState({});
    const [loading, setLoading] = useState(false);  
    const [currentStep, setCurrentStep] = useState(0);
    const [status, setStatus] = useState('Creating App...');
    
    const set = ({type, data}) => {
        setApp({...app, [type]: data});
    }

    /* Should submit app when clicking the next button */
    
    const submitApp = async () => {
        setLoading(true);
        const { message } = await APIConnection.createApp({app});
        app.id = message.id;
        await APIConnection.generateApp({app}, 
            (message) => {
                const parsedMessage = JSON.parse(message);
                setStatus(parsedMessage.message);
                setCurrentStep(parsedMessage.step || 0);
            },
            (error) => {
                store.dispatch(setNotification({error: true, message: JSON.parse(error).message}));
                setLoading(false);
            }
        );
        /* Deploy app */
        //await APIConnection.triggerDeployment({appId: app.id});
    }

    useEffect(() => {
        const totalSteps = 6; // Total number of steps
        const stepDuration = 1 * 60 * 1000; // 1 minute per step in milliseconds
        
        const updateAnimation = () => {
            const progress = (currentStep / totalSteps) * 100;
            document.querySelector('.modal-create-app').style.borderImageSource = `linear-gradient(to right, var(--primary-color) ${progress}%, transparent ${progress}%)`;
        };

        updateAnimation();
        
        // Set loading to true only when on the LoadingPage
        setLoading(currentStep >= 1); // Assuming LoadingPage is the second page
    }, [currentStep]);
    
    return (
        <div className="create-app-container">
            <Form {...props} 
                title={'Create App'}
                finalText={'Go to App'}  
                loading={loading}
                onStepChange={(step) => setCurrentStep(step)}
                onSubmit={submitApp} 
                set={setApp}
                pages={[
                    <div>
                        <SubmissionPage set={set} app={app}/>
                    </div>,
                    <LoadingPage setLoading={setLoading} set={set} app={app} status={status} currentStep={currentStep} />
                ]}
            />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user : state.user
    };
}

export default connect(mapStateToProps)(CreateAppPage);
