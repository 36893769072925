import { Component } from 'react';
import { axiosInstance, BASE_URL } from './utils'; // Ensure this points to the correct location of your axiosInstance setup
import CacheSingleton from '../lib/Cache';
import NotificationHandlerSingleton from '../lib/NotificationHandler';

class Connection extends Component {
    constructor(props) {
        super(props);
        this.ws = null; // WebSocket instance
    }

    openWebSocket = async (endpoint, onMessage, onError, onClose) => {
        return new Promise((resolve, reject) => {
            // Use wss:// if the page is loaded over HTTPS
            const protocol = window.location.protocol.includes('https') ? 'wss:' : 'ws:';
            /* Ensure wss is used if the page is loaded over HTTPS */
            this.baseUrl = BASE_URL.replace(/^http(s)?:/, protocol);
            this.ws = new WebSocket(this.baseUrl + endpoint);

            this.ws.onopen = () => {
                console.log('WebSocket connected');
                resolve();
            };

            this.ws.onmessage = (event) => {
                console.log('WebSocket message received:', event);
                if (onMessage) onMessage(event.data);
            };

            this.ws.onerror = (event) => {
                console.error('WebSocket error observed:', event);
                if (onError) onError(event);
                reject(event);
            };

            this.ws.onclose = (event) => {
                console.log('WebSocket is closed now:', event);
                if (onClose) onClose(event);
            };
        });
    }

    closeWebSocket = () => {
        if (this.ws) {
            this.ws.close();
        }
    }
    
    waitForSocketConnection = async () => {
        console.log('Waiting for WebSocket connection');
        return new Promise((resolve, reject) => {
            let counter = 0;
            const interval = setInterval(() => {
                if (this.ws.readyState === 1) {
                    console.log('WebSocket connected');
                    clearInterval(interval);
                    resolve();
                } else {
                    counter++;
                    if (counter >= 4) {
                        console.error('WebSocket connection failed');
                        clearInterval(interval);
                        reject();
                    }
                }
            }, 500);
        });
    }

    triggerDeployment = async ({ appId }, onMessage, onError, onClose) => {
        await this.openWebSocket('/apps/deploy', onMessage, onError, onClose);
        await this.waitForSocketConnection();

        // Get bearer and user id from Cache
        let { id, bearerToken } = CacheSingleton.getObject("auth");

        // Send data to WebSocket server
        this.ws.send(JSON.stringify({
            id: appId,
            bearerToken,
            user_id: id
        }));
    }

    triggerBuild = async ({ appId }, onMessage, onError, onClose) => {
        await this.openWebSocket('/apps/build', onMessage, onError, onClose);
        await this.waitForSocketConnection();

        // Get bearer and user id from Cache
        let { id, bearerToken } = CacheSingleton.getObject("auth");

        // Send data to WebSocket server
        this.ws.send(JSON.stringify({
            id: appId,
            bearerToken,
            user_id: id
        }));
    }

    // Process the response to return only the required data
    handleResponse = (response, showNotification = false) => {
        // Dispatch a notification with the response status and message
        if (showNotification) {
            NotificationHandlerSingleton
                .onNotification({
                    type: "Response",
                    code: response.data.status,
                    /* If Message is a string, then it's a simple message, if it's an object, show Request completed */
                    message: typeof response.data.message === 'string' ? response.data.message : 'Request completed',
                    show: true
                })
        }
        return response.data;
    }

    cleanResponse = (response) => {
        let res = this.handleResponse(response);
        if (res.status === 200) {
            return res.message.data;
        } else {
            throw new Error(res.message);
        }
    }

    // Centralize error handling and potentially throw to be caught by the caller
    handleError = (error) => {
        /* Send Notification */
        NotificationHandlerSingleton.onNotification({ message: error.message, status: "error" });
    }

    register = async ({ username, password, email, name }) => {
        try {
            const response = await axiosInstance.post('/users/register', { username, password, email, name });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    login = async ({ email, password }) => {
        try {
            const response = await axiosInstance.post('/users/login', { password, email });
            return this.handleResponse(response);
        } catch (error) {
            console.log(error)
            return this.handleError(error);
        }
    }

    googleLogin = async ({ googleBearerToken }) => {
        try {
            const response = await axiosInstance.post('/users/googleAuth', { googleBearerToken });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    auth = async () => {
        try {
            const response = await axiosInstance.post('/users/auth');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    requestResetPassword = async ({ email }) => {
        try {
            const response = await axiosInstance.post('/users/password/request', { email });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    resetPassword = async ({ token, password, email }) => {
        try {
            const response = await axiosInstance.post('/users/password/reset', { token, password, email });
            return this.handleResponse(response, true);
        } catch (error) {
            return this.handleError(error);
        }
    }

    createApp = async ({ app }) => {
        try {
            const response = await axiosInstance.post('/apps/create', app);
            return this.handleResponse(response, true);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getApp = async ({ app }) => {
        try {
            const response = await axiosInstance.get(`/apps/get/${app.id}`);
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    generateApp = async ({ app }, onMessage, onError, onClose) => {
        // Use WebSocket to communicate for this specific task
        await this.openWebSocket('/apps/generate', onMessage, onError, onClose);
        await this.waitForSocketConnection();

        // Get bearer and user id from Cache
        let { id, bearerToken } = CacheSingleton.getObject("auth");

        // Send data to WebSocket server
        this.ws.send(JSON.stringify({
            ...app,
            bearerToken,
            user_id: id
        }));
    }

    getStatus = async ({ id }) => {
        try {
            const response = await axiosInstance.get(`/apps/status/${id}`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getUsersInfo = async ({ id }) => {
        try {
            const response = await axiosInstance.get(`/apps/${id}/users`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getBuilds = async ({ id }) => {
        try {
            const response = await axiosInstance.get(`/apps/${id}/builds`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    cleanReponse = (response) => {
        let res = this.handleResponse(response);
        if (res.status === 200) {
            return res.message.data;
        } else {
            throw new Error(res.message);
        }
    }

    addDomain = async ({ id, domain }) => {
        try {
            const response = await axiosInstance.post(`/apps/${id}/domain/add`, { domain, id });
            return this.cleanReponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    };

    refreshACMStatus = async ({ id }) => {
        try {
            const response = await axiosInstance.post(`/apps/${id}/domain/refresh-acm`, { id });
            return this.cleanReponse(response, true);
        } catch (error) {
            return this.handleError(error);
        }
    };

    configureSSL = async ({ id }) => {
        try {
            const response = await axiosInstance.post(`/apps/${id}/domain/configure-ssl`, { id });
            return this.cleanReponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    };

    deleteDomain = async ({ id, domainId }) => {
        try {
            const response = await axiosInstance.delete(`/apps/${id}/domain/${domainId}`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    deleteApp = async ({ id }) => {
        try {
            const response = await axiosInstance.delete(`/apps/${id}`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    verifyElasticEmail = async ({ id }) => {
        try {
            const response = await axiosInstance.get(`/apps/${id}/elastic-email`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getAddon = async ({ name }) => {
        try {
            const response = await axiosInstance.get(`/addons/${name}`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getAppAddons = async ({ id }) => {
        try {
            const response = await axiosInstance.get(`/apps/${id}/addons`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    updateAddonEnvVariables = async ({ id, name, envVariables }) => {
        try {
            const response = await axiosInstance.post(`/apps/${id}/addons/${name}`, { envVariables });
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getStripeData = async ({ id, startDate, endDate }) => {
        try {
            const url = `/apps/${id}/stripe${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''}`;
            const response = await axiosInstance.get(url);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getUsersSummary = async ({ id, startDate, endDate }) => {
        try {
            const url = `/apps/${id}/users/summary${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''}`;
            const response = await axiosInstance.get(url);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getGoogleAnalyticsData = async ({ id, startDate, endDate }) => {
        try {
            const response = await axiosInstance.get(`/apps/${id}/google-analytics?startDate=${startDate}&endDate=${endDate}`);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getSessions = async ({ id, startDate, endDate }) => {
        try {
            /* Only send startDate and endDate if they are provided */
            const url = `/apps/${id}/sessions${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''}`;
            const response = await axiosInstance.get(url);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    editApp = async ({ id, app }) => {
        try {
            const response = await axiosInstance.post(`/apps/edit/${id}`, app);
            return this.cleanResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }
};

let APISingleton = new Connection();

export default APISingleton;  // Exporting an instance to be reused across the app
